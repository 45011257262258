import React from 'react'
import { Tag, Token } from '@revolut/ui-kit'

import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import {
  DocumentStatuses,
  DocumentUploadRequestInterface,
  EmployeeDocumentListItemInterface,
} from '@src/interfaces/documents'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import Table from '@src/components/TableV2/Table'
import { ColorTagVariant } from '@components/ColorTag/ColorTag'
import { formatWithoutTimezone } from '@src/utils/format'

export const documentName: ColumnInterface<EmployeeDocumentListItemInterface> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'document_name',
  sortKey: 'document_name',
  filterKey: 'id',
  selectorsKey: selectorKeys.none,
  title: 'Document Name',
  insert: ({ data }) => (
    <Table.EntityCell useIcon="DocumentsPair">{data.document_name}</Table.EntityCell>
  ),
}

export const documentCategory: ColumnInterface<EmployeeDocumentListItemInterface> = {
  type: CellTypes.insert,
  idPoint: 'category.id',
  dataPoint: 'category.name',
  sortKey: 'category__name',
  filterKey: 'category__id',
  selectorsKey: selectorKeys.document_categories,
  title: 'Category',
  insert: ({ data }) => (
    <Tag variant="faded" bg={Token.color.greyTone8} color={Token.color.foreground}>
      {data.category.name}
    </Tag>
  ),
}

export const documentSource: ColumnInterface<EmployeeDocumentListItemInterface> = {
  type: CellTypes.text,
  idPoint: 'source',
  dataPoint: 'source',
  sortKey: 'source',
  filterKey: 'source',
  selectorsKey: selectorKeys.employee_documents_sources,
  title: 'Source',
}

export const documentIssueDateTime: ColumnInterface<EmployeeDocumentListItemInterface> = {
  type: CellTypes.date,
  idPoint: 'issued_date_time',
  dataPoint: 'issued_date_time',
  sortKey: 'issued_date_time',
  filterKey: 'issued_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Issued on',
}

export const documentSigningDateTime: ColumnInterface<EmployeeDocumentListItemInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'signed_date_time',
    dataPoint: 'signed_date_time',
    sortKey: 'signed_date_time',
    filterKey: 'signed_date_time',
    selectorsKey: selectorKeys.none,
    title: 'Completed on',
    insert: ({ data }) =>
      data.signed_date_time ? formatWithoutTimezone(data.signed_date_time) : 'n/a',
  }

export const documentIssuer: ColumnInterface<EmployeeDocumentListItemInterface> = {
  type: CellTypes.insert,
  idPoint: 'issuer.id',
  dataPoint: 'issuer.full_name',
  sortKey: 'issuer__full_name',
  filterKey: 'issuer__id',
  selectorsKey: selectorKeys.employee,
  title: 'Issuer',
  insert: ({ data }) => <Table.EmployeeCell employee={data.issuer} />,
}

export const documentEmployee: ColumnInterface<EmployeeDocumentListItemInterface> = {
  type: CellTypes.insert,
  idPoint: 'employee.id',
  dataPoint: 'employee.full_name',
  sortKey: 'employee__full_name',
  filterKey: 'employee__id',
  selectorsKey: selectorKeys.employee,
  title: 'Employee',
  insert: ({ data }) => <UserWithAvatar {...data.employee} />,
}

export const documentAssignee: ColumnInterface<EmployeeDocumentListItemInterface> = {
  type: CellTypes.insert,
  idPoint: 'employee.id',
  dataPoint: 'employee.full_name',
  sortKey: 'employee__full_name',
  filterKey: 'employee__id',
  selectorsKey: selectorKeys.employee,
  title: 'Assignee',
  insert: ({ data }) => <UserWithAvatar {...data.employee} />,
}

export const getDocumentStatusColor = (status: DocumentStatuses) => {
  switch (status) {
    case DocumentStatuses.pending:
    case DocumentStatuses.pending_upload:
    case DocumentStatuses.pending_approval:
      return Token.color.orange
    case DocumentStatuses.completed:
      return Token.color.green
    default:
      return Token.color.foreground
  }
}

const getStatusVariant = (status: DocumentStatuses): ColorTagVariant => {
  switch (status) {
    case DocumentStatuses.pending:
    case DocumentStatuses.pending_upload:
    case DocumentStatuses.pending_approval:
      return 'warning'

    default:
      return 'neutral'
  }
}

export const documentStatus: ColumnInterface<EmployeeDocumentListItemInterface> = {
  type: CellTypes.insert,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.employee_documents_statuses,
  colors: data => getDocumentStatusColor(data.status),
  insert: ({ data }) => (
    <Table.StatusCell status={data.status} variant={getStatusVariant(data.status)} />
  ),
  title: 'Status',
}

export const documentUploadRequestFileNameColumn: ColumnInterface<DocumentUploadRequestInterface> =
  {
    type: CellTypes.text,
    idPoint: 'file_name',
    dataPoint: 'file_name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'File name',
  }

export const documentUploadRequestUploadedByColumn: ColumnInterface<DocumentUploadRequestInterface> =
  {
    type: CellTypes.insert,
    idPoint: '',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Uploaded by',
    insert: ({ data }) => <UserWithAvatar {...data.uploaded_by} />,
  }

export const documentUploadRequestUploadedOnColumn: ColumnInterface<DocumentUploadRequestInterface> =
  {
    type: CellTypes.dateTime,
    idPoint: 'uploaded_on',
    dataPoint: 'uploaded_on',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Uploaded on',
  }

export const documentUploadRequestActionsColumn: ColumnInterface<DocumentUploadRequestInterface> =
  {
    type: CellTypes.insert,
    idPoint: '',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Actions',
  }
