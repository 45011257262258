import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import React, { useState } from 'react'
import { ActionButton, Spinner } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface } from '@src/interfaces/goals'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useLocation } from 'react-router-dom'
import { useGetReviewCycles } from '@src/api/reviewCycles'
import { EntityTypes } from '@src/constants/api'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { captureException } from '@sentry/core'
import { getDefaultBackRoute, useSubmitFlowHelpers } from './common/utils'
import { deleteGoal } from '@src/api/goals'
import { goBack, navigateReplace } from '@src/actions/RouterActions'
import { FormObserverProvider } from './Form/Widgets/FormObserverProvider'
import { useGoalFormSubmit } from './Form/useGoalFormSubmit'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import { GoalFormPageBody } from './GoalFormPageBody'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { cleanGoalCache, cleanMetricCache } from './useGoalFormCache'
import { isNumber, isString } from 'lodash'

export const GoalFormPage = () => {
  const { values } = useLapeContext<GoalsInterface>()
  const { data: performanceSettings } = useGetPerformanceSettings()
  const { data: reviewCycles } = useGetReviewCycles()

  const [autoDeletePending, setAutoDeletePending] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const location = useLocation<{
    reviewCycleId?: string
    history: string[]
    isNew?: boolean
  }>()
  const { confirm, prompt, showError, showLoading, confirmationDialog } =
    useSubmitFlowHelpers()
  const { submit } = useGoalFormSubmit()
  const isOnboarding = isOnboardingPath()

  const isDraft = values.approval_status.id === ApprovalStatuses.Draft
  const isNew = !!location.state?.isNew

  const shouldAutoDelete = isDraft && isNew
  const shouldShowDelete = isDraft && !isNew
  const shouldConfirmBack = isDraft && isNew && isDirty

  const backUrl = getDefaultBackRoute(values, isOnboarding)

  const contentLabelByType: Record<EntityTypes, string> = {
    [EntityTypes.department]: 'department',
    [EntityTypes.team]: 'team',
    [EntityTypes.teams]: 'team',
    [EntityTypes.employees]: 'employee',
    [EntityTypes.employee]: 'employee',
    [EntityTypes.company]: 'company',
    [EntityTypes.companyV2]: 'company',
    [EntityTypes.function]: 'function',
    [EntityTypes.role]: 'role',
    [EntityTypes.specialisation]: 'specialisation',
    [EntityTypes.functions]: 'function',
    [EntityTypes.roles]: 'role',
    [EntityTypes.specialisations]: 'specialisation',
  }

  const isDefaultCycle = (cycle: ReviewCyclesInterface) => {
    if (location.state?.reviewCycleId && isNumber(location.state?.reviewCycleId)) {
      return cycle.id === Number(location.state.reviewCycleId) || cycle.offset === 0
    }
    if (location.state?.reviewCycleId && isString(location.state?.reviewCycleId)) {
      return undefined
    }
    return cycle.offset === 0
  }

  const entityTitle = values.content_type?.model
    ? contentLabelByType[values.content_type.model]
    : 'company'

  const title = isNew
    ? `Add new ${entityTitle} goal`
    : isDraft
    ? `Edit ${entityTitle} draft goal`
    : `Edit ${entityTitle} goal`

  const deleteAndGoBack = async () => {
    setAutoDeletePending(true)
    await deleteGoal(values.id).catch(captureException)
    for (const metric of values.kpis) {
      metric.tempId && cleanMetricCache(metric.tempId)
      metric.id && cleanMetricCache(metric.id)
    }
    setAutoDeletePending(false)
    cleanGoalCache(values.id)
    goBack(backUrl)
  }

  const confirmBack = async () => {
    const confirmVariant = values.name ? confirm : prompt

    const confirmed = await confirmVariant({
      yesMessage: 'Save as draft',
      noMessage: 'Delete goal',
      noBtnVariant: 'negative',
      variant: 'compact',
      label: 'You have unsaved changes',
      body: 'Do you want to save them or delete before proceeding?',
      promptLabel: 'Please set goal name before saving',
      commentRequired: true,
    })

    if (confirmed.status === 'canceled') {
      return
    }

    if (confirmed.status === 'confirmed') {
      if (confirmed.comment) {
        values.name = confirmed.comment
      }
      await submit('draft')
      goBack(backUrl)
    }

    if (confirmed.status === 'rejected') {
      deleteAndGoBack()
    }
  }

  const handleBack = () => {
    if (shouldConfirmBack) {
      confirmBack()
    } else if (shouldAutoDelete) {
      deleteAndGoBack()
    } else {
      goBack(backUrl)
    }
  }

  const onDeleteGoal = async () => {
    const confirmed = await confirm({
      yesMessage: 'Delete',
      yesBtnVariant: 'negative',
      noMessage: 'Cancel',
      variant: 'compact',
      body: 'Do you want to delete this goal?',
    })

    const navigateBack = () => {
      const history: string[] | undefined = location.state?.history
      const isLastWasPreview = history?.at(-1)?.match(RegExp(`/goal/.*/${values.id}.*`))

      if (isLastWasPreview) {
        navigateReplace(backUrl)
      } else {
        goBack(backUrl, undefined, true)
      }
    }

    if (confirmed.status === 'confirmed') {
      try {
        const hidePopup = showLoading('Deleting...')
        await deleteGoal(values.id)
        hidePopup()
        navigateBack()
      } catch (err) {
        captureException(err)
        showError('Failed to delete goal', 'Please try again')
      }
    }
  }

  const onSubmitted = () => {
    if (values.approval_status.id === ApprovalStatuses.Draft) {
      goBack(backUrl)
    } else {
      navigateReplace(
        pathToUrl(
          isOnboarding
            ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.GOAL.PREVIEW
            : ROUTES.FORMS.GOAL.PREVIEW,
          { id: values.id },
        ),
      )
    }
  }

  return performanceSettings ? (
    <PageWrapper>
      <PageHeader
        variant="narrow"
        noWrap={false}
        title={title}
        backUrl={backUrl}
        backButton={autoDeletePending ? <Spinner /> : undefined}
        withVerticalSpacing
        onClickBack={handleBack}
        subtitle={
          shouldShowDelete && (
            <ActionButton useIcon="Delete" variant="negative" onClick={onDeleteGoal}>
              Delete
            </ActionButton>
          )
        }
        hideGlobalSearch={isOnboarding}
      />
      <FormObserverProvider>
        <GoalFormPageBody
          onDirtyChanged={setIsDirty}
          defaultReviewCycle={reviewCycles?.results.find(isDefaultCycle)}
          onSubmitted={onSubmitted}
          performanceSettings={performanceSettings}
        />
      </FormObserverProvider>
      {confirmationDialog}
    </PageWrapper>
  ) : null
}
