import { HStack, Tag, Text, Token } from '@revolut/ui-kit'
import {
  InterviewRoundInterface,
  InterviewStageWithoutRoundInterface,
  InterviewStatuses,
} from '@src/interfaces/interviewTool'
import React from 'react'

type StageTitleProps = {
  round: InterviewRoundInterface
  stage: InterviewStageWithoutRoundInterface
}

export const CurrentStage = () => {
  return <Tag color={Token.color.accent}>Current</Tag>
}

export const StageTitle = ({ round, stage }: StageTitleProps) => {
  const title = (
    <Text
      variant="emphasis1"
      wrap="nowrap"
      color={
        stage.scheduling_status === InterviewStatuses.not_started
          ? Token.color.greyTone50
          : undefined
      }
    >
      {stage.title}
    </Text>
  )
  if (round?.latest_interview_stage?.id !== stage.id) {
    return title
  }
  return (
    <HStack gap="s-6" align="center">
      {title} <CurrentStage />
    </HStack>
  )
}
