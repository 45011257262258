import React, { useEffect } from 'react'
import { Avatar, Group, Item, ItemSkeleton, Text, Token, Widget } from '@revolut/ui-kit'
import { formatPercentage } from '@src/utils/format'
import { useGetReviewGradesMap } from '@src/utils/grades'
import {
  GradeItem,
  GradeItemSkeleton,
} from '@src/pages/Forms/ReviewCycle/ReviewCycle/CalibrationLogic/EditCalibrationLogic/GradesDistribution/GradeItem'
import {
  validateGradesDistribution,
  ValidationResult,
} from '@src/pages/Forms/ReviewCycle/ReviewCycle/CalibrationLogic/EditCalibrationLogic/validation'
import NewWarningMessage from '@components/NewWarningMessage/NewWarningMessage'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'

const minDistributionValue = 0
const maxDistributionValue = 1
const step = 0.01

const title = 'Calibration grade distribution'
const description =
  'The calibration logic sets a benchmark for expected results by defining the percentage of employees you expect to achieve each performance grade'

interface Props {
  gradesDistribution: Record<string, number>
  handleOnValidate: (validationResult: ValidationResult) => void
}

export const GradesDistribution = ({ gradesDistribution, handleOnValidate }: Props) => {
  const { values } = useLapeContext<ReviewCyclesInterface>()
  const { gradesMap, isLoading } = useGetReviewGradesMap()
  const validationResult = validateGradesDistribution(gradesDistribution)

  useEffect(() => {
    handleOnValidate(validationResult)
  }, [validationResult.isValid])

  const isIncrementEnabled = (id: string) => gradesDistribution[id] < maxDistributionValue
  const isDecrementEnabled = (id: string) => gradesDistribution[id] > minDistributionValue

  const makeIncrementHandler = (id: string) => () => {
    values.grade_distribution = {
      ...gradesDistribution,
      [id]: Number((gradesDistribution[id] + step).toFixed(2)),
    }
  }

  const makeDecrementHandler = (id: string) => () => {
    values.grade_distribution = {
      ...gradesDistribution,
      [id]: Number((gradesDistribution[id] - step).toFixed(2)),
    }
  }

  if (isLoading) {
    return <GradesDistributionSkeleton />
  }

  return (
    <Widget>
      <Item>
        <Item.Avatar>
          <Avatar useIcon="Wealth" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>{title}</Item.Title>
          <Item.Description>{description}</Item.Description>
          <Group>
            {Object.entries(gradesDistribution).map(([id, value]) => (
              <GradeItem
                id={id}
                key={id}
                name={gradesMap[id]}
                value={formatPercentage(value)}
                description="% of employees"
                isIncrementDisabled={!isIncrementEnabled(id)}
                isDecrementDisabled={!isDecrementEnabled(id)}
                handleIncrementClick={makeIncrementHandler(id)}
                handleDecrementClick={makeDecrementHandler(id)}
              />
            ))}
          </Group>
        </Item.Content>
      </Item>
      {!validationResult.isValid && (
        <NewWarningMessage
          margin="s-4"
          style={{ backgroundColor: Token.color.inputError }}
        >
          <Text color={Token.color.red}>{validationResult.messages}</Text>
        </NewWarningMessage>
      )}
    </Widget>
  )
}

const GradesDistributionSkeleton = () => (
  <ItemSkeleton>
    <ItemSkeleton.Avatar />
    <ItemSkeleton.Content>
      <ItemSkeleton.Title />
      <ItemSkeleton.Description />
      <GradeItemSkeleton />
      <GradeItemSkeleton />
      <GradeItemSkeleton />
      <GradeItemSkeleton />
      <GradeItemSkeleton />
    </ItemSkeleton.Content>
  </ItemSkeleton>
)
