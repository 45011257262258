import React, { CSSProperties } from 'react'
import { ProgressWidget, ProgressWidgetSkeleton, Token } from '@revolut/ui-kit'
import { getColorForPortion } from '@src/pages/Forms/ReviewCycle/ReviewCycle/components/Progress/styles'
import { formatPrecisePercentage } from '@src/pages/Forms/ReviewCycle/ReviewCycle/formatters/formatPrecisePercentage'

const widgetStylesOverrides: CSSProperties = {
  padding: 0,
  backgroundColor: Token.color.widgetBackground,
}

export const makeDescriptionText = (dividend: number, divisor: number): string =>
  divisor > 0 ? formatPrecisePercentage(dividend / divisor) : 'N/A'

interface Props {
  value: number
  title: React.ReactNode
  description: React.ReactNode
  isLoading: boolean
  label: React.ReactNode
}

export const Progress = ({ value, title, description, isLoading, label }: Props) => {
  const color = getColorForPortion(value)

  if (isLoading) {
    return <ProgressWidgetSkeleton style={widgetStylesOverrides} />
  }

  return (
    <ProgressWidget style={widgetStylesOverrides}>
      <ProgressWidget.Title>{title}</ProgressWidget.Title>
      <ProgressWidget.Description color={color}>{description}</ProgressWidget.Description>
      <ProgressWidget.Progress value={value} color={color} />
      <ProgressWidget.Label>{label}</ProgressWidget.Label>
    </ProgressWidget>
  )
}
