import React from 'react'
import { EligibleGroupInterface } from '@src/interfaces/reviewCycles'
import { ActionButton, Avatar, chain, Item, Widget } from '@revolut/ui-kit'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useParams } from 'react-router'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { EligibleGroupList } from '@src/pages/Forms/ReviewCycle/ReviewCycle/EligibleEmployees/EligibleGroupList'

interface Props {
  count: number
  groups: EligibleGroupInterface[]
  isLoading: boolean
}

export const EligibleEmployees = ({ count, groups, isLoading }: Props) => {
  const params = useParams<{ id: string }>()
  const permissions = useSelector(selectPermissions)

  const canViewEligibleEmployees = permissions.includes(
    PermissionTypes.ViewReviewsWhitelist,
  )
  const employeesListPath = pathToUrl(ROUTES.FORMS.REVIEW_CYCLES.ELIGIBLE_EMPLOYEES_LIST)
  const editEmployeesPath = pathToUrl(
    ROUTES.FORMS.REVIEW_CYCLES.ELIGIBLE_EMPLOYEES,
    params,
  )
  const title = chain('Eligible employees', count)

  return (
    <Widget>
      <Item>
        <Item.Avatar>
          <Avatar useIcon="People" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>{title}</Item.Title>
          <Item.Description>
            Total number of employees who are eligible to participate in this cycle
          </Item.Description>
          {canViewEligibleEmployees && (
            <Item.Actions>
              <ActionButton
                useIcon="16/ListBullet"
                to={employeesListPath}
                use={InternalLink}
              >
                All employees
              </ActionButton>
            </Item.Actions>
          )}
        </Item.Content>
        <Item.Side>
          <Item.Value>
            <ActionButton to={editEmployeesPath} use={InternalLink}>
              Edit
            </ActionButton>
          </Item.Value>
        </Item.Side>
      </Item>
      <EligibleGroupList groups={groups} isLoading={isLoading} />
    </Widget>
  )
}
