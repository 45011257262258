import React, { useMemo } from 'react'
import { Flex, Icon, HStack, Text, Widget } from '@revolut/ui-kit'
import { useGetEmployeeTotalCompensationTimeSeries } from '@src/api/totalCompensation'
import Tooltip from '@components/Tooltip/Tooltip'
import { CompensationParams } from '@src/pages/EmployeeProfile/Layout/common/types'
import { BarChart } from '../Charts/BarChart'
import { useBarProjectedData } from '../helpers'

type Props = {
  compensationParams: CompensationParams
  id: number
}

const DATA_KEYS = [
  {
    id: 0,
    label: 'Base salary',
    value: 'base_salary',
  },
  {
    id: 1,
    label: 'Vested equity',
    value: 'vested_equity',
  },
  {
    id: 2,
    label: 'Cash bonus',
    value: 'cash_bonus',
  },
  {
    id: 3,
    label: 'Sold equity',
    value: 'sold_equity',
  },
] as const

export const CompensationOverTime = ({ compensationParams, id }: Props) => {
  const { currency } = compensationParams
  const filters = currency
    ? [
        {
          filters: [{ id: `${currency}`, name: `${currency}` }],
          columnName: 'currency_iso_code',
        },
      ]
    : []

  const { data, isLoading } = useGetEmployeeTotalCompensationTimeSeries(id, filters)
  const projectedDataIdx = useBarProjectedData(data)

  const dataKeys = useMemo(() => {
    if (!data) {
      return []
    }

    return DATA_KEYS.filter(
      key => data.filter(timeSeries => Number(timeSeries[key.value])).length > 0,
    )
  }, [data])

  const chartData = useMemo(() => {
    if (!data) {
      return []
    }

    return data.map(value => ({
      label: '',
      tooltip: '',
      ...value,
    }))
  }, [data])

  if (!isLoading && !data?.length) {
    return null
  }

  return (
    <Widget height="50vh" p="s-16" data-testid="compensation_over_time_section">
      <Flex flexDirection="column" height="100%">
        <HStack gap="s-4">
          <Text variant="emphasis2">Compensation over time</Text>
          <Tooltip
            placement="bottom"
            text="Review your past earnings and expected compensation for future years, including upcoming vested equity and base salary (based on your current contractual details) but not including future bonuses."
          >
            <Icon name="InfoOutline" size={16} />
          </Tooltip>
        </HStack>

        <BarChart
          data={chartData}
          dataKeys={dataKeys}
          isLoading={isLoading}
          projectedDataIdx={projectedDataIdx}
        />
      </Flex>
    </Widget>
  )
}
