import { IconName } from '@revolut/ui-kit'

export enum CultureValueType {
  Culture = 'cultural_value',
  Management = 'management_behaviour',
}

export const CultureValueTypeTitle = {
  [CultureValueType.Culture]: 'Cultural value',
  [CultureValueType.Management]: 'Management behaviour',
}

export interface CultureValueInterface {
  behaviours: BehaviourInterface[]
  category: CultureValueType
  description: string
  id: number
  is_enabled: boolean
  name: string
  playbook_link: string
  icon: IconName | null
}

export interface BehaviourInterface {
  id?: number
  name: string
  description: string
}
