import React, { useEffect, useState } from 'react'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { OptionInterface } from '@src/interfaces/selectors'
import { Box } from '@revolut/ui-kit'
import CompetencyMatrixTable from '@src/features/CompetencyMatrixTable/CompetencyMatrixTable'
import { useGetExampleRoles } from '@src/api/roles'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import Table from '@src/components/TableV2/Table'

interface Props {
  seniorities: SeniorityInterface[]
  minSeniority?: SeniorityInterface
  maxSeniority?: SeniorityInterface
}

const CompetencyMatrixExample = ({ seniorities, ...rest }: Props) => {
  const [selectedRole, setSelectedRole] = useState<OptionInterface>()
  const { data: roles = [] } = useGetExampleRoles()
  const role = roles.find(item => item.id === selectedRole?.id)
  const options = roles.map(item => ({ id: item.id, name: item.name }))

  useEffect(() => {
    if (roles.length && !selectedRole) {
      setSelectedRole(options[0])
    }
  }, [roles])

  const disabled = !seniorities.length || !roles.length

  return !disabled ? (
    <>
      <Box mb="s-12" width={224}>
        <RadioSelectInput<OptionInterface>
          label="Role"
          value={selectedRole || null}
          onChange={option => {
            setSelectedRole(option as OptionInterface)
          }}
          options={options.map(option => ({ label: option.name, value: option }))}
        />
      </Box>
      {role && (
        <Table.Widget>
          <Table.Widget.Table>
            <CompetencyMatrixTable
              isV2Table
              competencyMatrices={[
                {
                  children: role.functional_competency_matrix,
                  sectionTitle: '',
                  disabled: true,
                },
              ]}
              isAdjustable={false}
              {...rest}
            />
          </Table.Widget.Table>
        </Table.Widget>
      )}
    </>
  ) : null
}

export default CompetencyMatrixExample
