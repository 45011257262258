import React from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { useSidebarContext } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Sidebar/SidebarProvider'

export const GoalsActions = () => {
  const { show } = useSidebarContext()

  return (
    <MoreBar>
      <MoreBar.Action useIcon="LinkExternal" onClick={show}>
        Review goals
      </MoreBar.Action>
    </MoreBar>
  )
}
