import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'
import { isInDateRange } from '@src/utils/timezones'
import { compareAsc, compareDesc, endOfDay, startOfDay } from 'date-fns'
import { notReachable } from '@src/utils/notReachable'
import { cycleModel } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/CycleModel'

export enum ReviewCycleSubStage {
  EmployeeReview = 'EmployeeReview',
  ManagerReview = 'ManagerReview',
  HODCalibration = 'HODCalibration',
  HOFCalibration = 'HOFCalibration',
}

// TODO: static class will be rewritten with functions later in scope of https://revolut.atlassian.net/browse/REVC-7007
//  not to have confusing changes in current PR review
export class TimelineModel {
  static readonly defaultStage = ReviewCycleStage.DepartmentGoals

  static readonly getSubStages = (stage: ReviewCycleStage): ReviewCycleSubStage[] => {
    if (stage === ReviewCycleStage.Review) {
      return [ReviewCycleSubStage.EmployeeReview, ReviewCycleSubStage.ManagerReview]
    }

    if (stage === ReviewCycleStage.Calibration) {
      return [ReviewCycleSubStage.HODCalibration, ReviewCycleSubStage.HOFCalibration]
    }

    return []
  }

  static readonly getCurrentStage = (
    cycle: ReviewCyclesInterface,
    availableStages: ReviewCycleStage[],
  ): ReviewCycleStage => {
    if (cycleModel.isManual(cycle)) {
      return cycle.current_stage ?? TimelineModel.defaultStage
    }

    return TimelineModel.getCurrentStageByDates(cycle, availableStages)
  }

  static readonly getCurrentStageByDates = (
    cycle: ReviewCyclesInterface,
    availableStages: ReviewCycleStage[],
  ): ReviewCycleStage => {
    const ongoingStages = availableStages.filter(stage =>
      TimelineModel.isOngoingStage(cycle, stage),
    )

    return ongoingStages.length > 0
      ? ongoingStages[ongoingStages.length - 1]
      : TimelineModel.defaultStage
  }

  static readonly getCalibrationStageStartDay = (
    cycle: ReviewCyclesInterface,
  ): Date | null => {
    const [firstCalibrationDay] = [
      cycle.department_owner_calibration_start_day,
      cycle.head_of_function_calibration_start_day,
    ]
      .filter(Boolean)
      .map<Date>(dateString => new Date(dateString))
      .sort(compareAsc)

    return firstCalibrationDay ?? null
  }

  static readonly getReviewStageEndDay = (cycle: ReviewCyclesInterface): Date | null => {
    const [lastReviewDay] = [
      cycle.self_and_peer_reviews_last_day,
      cycle.managers_reviews_last_day,
    ]
      .filter(Boolean)
      .map<Date>(dateString => new Date(dateString))
      .sort(compareDesc)

    return lastReviewDay ?? null
  }

  static readonly getPeriodStartDay = (
    cycle: ReviewCyclesInterface,
    period: ReviewCycleStage | ReviewCycleSubStage,
  ): Date | null => {
    const {
      department_kpi_period_start_day: depGoalsStartDay,
      team_kpi_period_start_day: teamGoalsStartDay,
      review_period_start_day: reviewStartDay,
      department_owner_calibration_start_day: hODCalibrationStartDay,
      head_of_function_calibration_start_day: hOFCalibrationStartDay,
      managers_publishing_day: managersPublishDay,
      reviews_publishing_day: employeesPublishDay,
    } = cycle

    switch (period) {
      case ReviewCycleStage.DepartmentGoals:
        return depGoalsStartDay ? startOfDay(new Date(depGoalsStartDay)) : null

      case ReviewCycleStage.TeamGoals:
        return teamGoalsStartDay ? startOfDay(new Date(teamGoalsStartDay)) : null

      case ReviewCycleStage.Review:
        return reviewStartDay ? new Date(reviewStartDay) : null

      case ReviewCycleStage.Calibration:
        return TimelineModel.getCalibrationStageStartDay(cycle)

      case ReviewCycleStage.ManagersPublish:
        return managersPublishDay ? new Date(managersPublishDay) : null

      case ReviewCycleStage.EmployeesPublish:
      case ReviewCycleStage.Completed:
        return employeesPublishDay ? new Date(employeesPublishDay) : null

      case ReviewCycleSubStage.EmployeeReview:
      case ReviewCycleSubStage.ManagerReview:
        return reviewStartDay ? new Date(reviewStartDay) : null

      case ReviewCycleSubStage.HODCalibration:
        return hODCalibrationStartDay ? new Date(hODCalibrationStartDay) : null

      case ReviewCycleSubStage.HOFCalibration:
        return hOFCalibrationStartDay ? new Date(hOFCalibrationStartDay) : null

      default:
        return notReachable(period)
    }
  }

  static readonly getPeriodEndDay = (
    cycle: ReviewCyclesInterface,
    period: ReviewCycleStage | ReviewCycleSubStage,
  ): Date | null => {
    const {
      department_kpi_period_end_day: depGoalsEndDay,
      team_kpi_period_end_day: teamGoalsEndDay,
      self_and_peer_reviews_last_day: employeesReviewEndDay,
      managers_reviews_last_day: managersReviewEndDay,
      head_of_function_and_department_last_calibration_day: calibrationEndDay,
      managers_publishing_day: managersPublishEndDay,
      reviews_publishing_day: employeesPublishEndDay,
    } = cycle

    switch (period) {
      case ReviewCycleStage.DepartmentGoals:
        return depGoalsEndDay ? endOfDay(new Date(depGoalsEndDay)) : null

      case ReviewCycleStage.TeamGoals:
        return teamGoalsEndDay ? endOfDay(new Date(teamGoalsEndDay)) : null

      case ReviewCycleStage.Review:
        return TimelineModel.getReviewStageEndDay(cycle)

      case ReviewCycleStage.Calibration:
        return calibrationEndDay ? new Date(calibrationEndDay) : null

      case ReviewCycleStage.ManagersPublish:
        return managersPublishEndDay ? endOfDay(new Date(managersPublishEndDay)) : null

      case ReviewCycleStage.EmployeesPublish:
      case ReviewCycleStage.Completed:
        return employeesPublishEndDay ? endOfDay(new Date(employeesPublishEndDay)) : null

      case ReviewCycleSubStage.EmployeeReview:
        return employeesReviewEndDay ? new Date(employeesReviewEndDay) : null

      case ReviewCycleSubStage.ManagerReview:
        return managersReviewEndDay ? new Date(managersReviewEndDay) : null

      case ReviewCycleSubStage.HODCalibration:
      case ReviewCycleSubStage.HOFCalibration:
        return calibrationEndDay ? new Date(calibrationEndDay) : null

      default:
        return notReachable(period)
    }
  }

  static readonly isOngoingStage = (
    cycle: ReviewCyclesInterface,
    stage: ReviewCycleStage,
  ): boolean => {
    if (cycleModel.isManual(cycle)) {
      return cycle.current_stage === stage
    }

    const today = new Date()
    const startDay = TimelineModel.getPeriodStartDay(cycle, stage)
    const endDay = TimelineModel.getPeriodEndDay(cycle, stage)

    if (!startDay || !endDay) {
      return false
    }

    return isInDateRange(startDay, endDay, today)
  }
}
