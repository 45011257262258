import React from 'react'
import capitalize from 'lodash/capitalize'
import { Text } from '@revolut/ui-kit'

import Table from '@components/TableV2/Table'
import { selectorKeys } from '@src/constants/api'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { LinkedAccountsInterface } from '@src/interfaces/linkedAccounts'
import { mapAccountStatusToProps } from '@src/utils/linkedAccounts'

export const linkedDateColumn: ColumnInterface<LinkedAccountsInterface> = {
  type: CellTypes.date,
  idPoint: 'created_date_time',
  dataPoint: 'created_date_time',
  sortKey: 'created_date_time',
  filterKey: 'created_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Linked Date',
}

export const linkedApplicationColumn: ColumnInterface<LinkedAccountsInterface> = {
  type: CellTypes.text,
  idPoint: 'account_type.id',
  dataPoint: 'account_type.name',
  sortKey: 'account_type',
  filterKey: 'account_type',
  selectorsKey: selectorKeys.employees_linked_accounts_types,
  title: 'Application',
}

export const linkedPhoneNumberColumn: ColumnInterface<LinkedAccountsInterface> = {
  type: CellTypes.text,
  idPoint: 'personal_phone_number',
  dataPoint: 'personal_phone_number',
  sortKey: 'personal_phone_number',
  filterKey: 'personal_phone_number',
  selectorsKey: selectorKeys.none,
  title: 'Linked phone number',
}

export const linkedEmailColumn: ColumnInterface<LinkedAccountsInterface> = {
  type: CellTypes.insert,
  idPoint: 'personal_email',
  dataPoint: 'personal_email',
  sortKey: 'personal_email',
  filterKey: 'personal_email',
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    return <Text>{data.personal_email || '-'}</Text>
  },
  title: 'Linked Email',
}

export const formatLinkedAccountsStatus = (data: LinkedAccountsInterface) =>
  capitalize(data.status?.replace('_', ' ') || '-')

export const linkedStatusColumn: ColumnInterface<LinkedAccountsInterface> = {
  type: CellTypes.insert,
  colors: data => mapAccountStatusToProps(data.status).color,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: 'status',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Status',
  insert: ({ data }) => {
    const { statusText, variant } = mapAccountStatusToProps(data.status)
    return <Table.StatusCell variant={variant} status={statusText} />
  },
}
