import React from 'react'
import { Action, Flex, Icon, HStack, Text, Widget } from '@revolut/ui-kit'
import { useGetEmployeeTotalCompensationStats } from '@src/api/totalCompensation'
import { ROUTES } from '@src/constants/routes'
import { CurrencySelect } from '@components/CurrencySelect/CurrencySelect'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import Tooltip from '@components/Tooltip/Tooltip'
import { CompensationParams } from '@src/pages/EmployeeProfile/Layout/common/types'
import { formatMoney } from '@src/utils/format'
import { pathToUrl } from '@src/utils/router'
import { PieChart } from '../Charts/PieChart'

type Props = {
  compensationParams: CompensationParams
  id: number
}

export const CompensationTotal = ({ compensationParams, id }: Props) => {
  const { currency, setCurrency } = compensationParams
  const filters = currency
    ? [
        {
          filters: [{ id: `${currency}`, name: `${currency}` }],
          columnName: 'currency_iso_code',
        },
      ]
    : []

  const { data, isLoading } = useGetEmployeeTotalCompensationStats(id, filters)

  if (!isLoading && !data) {
    return null
  }

  const pieChartData = [
    {
      name: 'Base salary',
      value: data ? Number(data.current_year_base_salary) : 0,
    },
  ]

  return (
    <Flex
      alignItems="stretch"
      flexDirection="column"
      height="50vh"
      gap="s-16"
      data-testid="total_compensation_section"
    >
      <Widget flex={1} p="s-16">
        <Flex alignItems="stretch" flexDirection="column" height="100%">
          <Flex alignItems="center" gap="s-4">
            <Text variant="emphasis2">Base salary</Text>
            <Tooltip placement="bottom" text="Latest base salary for the current year.">
              <Icon name="InfoOutline" size={16} />
            </Tooltip>
          </Flex>

          <CurrencySelect
            label=""
            onCurrencyChange={({ iso_code }) => setCurrency(iso_code)}
            value={data?.currency.iso_code}
          />

          <PieChart
            currency={data?.currency.iso_code}
            data={pieChartData}
            hideLegend
            isLoading={isLoading}
            total={data ? Number(data.current_year_base_salary) : undefined}
          />
        </Flex>
      </Widget>

      {data && !!Number(data.total_equity) && (
        <Widget p="s-16">
          <Flex alignItems="center" justifyContent="space-between" mb="s-16">
            <HStack gap="s-4">
              <Text variant="emphasis2">Total equity</Text>
              <Tooltip
                placement="bottom"
                text="The total value of your equity, including vested and unvested shares based on the latest share price valuation, and sold shares based on sell price."
              >
                <Icon name="InfoOutline" size={16} />
              </Tooltip>
            </HStack>
            <Action
              to={pathToUrl(ROUTES.FORMS.EMPLOYEE.EQUITY_OVERVIEW, { id: String(id) })}
              use={InternalLink}
            >
              Detail view
            </Action>
          </Flex>

          <Text textAlign="center" variant="heading2" use="div">
            {formatMoney(Number(data.total_equity), data.currency.iso_code)}
          </Text>
        </Widget>
      )}
    </Flex>
  )
}
