import React from 'react'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import {
  JobPostingListInterface,
  LocationSource,
  PublishingStatuses,
  RecruiterLocationInterface,
} from '@src/interfaces/jobPosting'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import Tooltip from '@components/Tooltip/Tooltip'
import styled from 'styled-components'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Flex, HStack, IconButton, Text, Token } from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import Table from '@components/TableV2/Table'
import { MultipleEmployees } from '@components/ColumnInserts/MultipleEmployees/MultipleEmployees'
import { getDefaultApprovalStatusVariant } from '@components/TableV2/Cells/StatusCell'
import { FUNCTION_DEFAULT_ICON } from '@src/constants/common'

const StyledTooltip = styled(Tooltip)`
  justify-content: flex-start;
`

export const jobDescriptionIconColumn: ColumnInterface<JobPostingListInterface> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.job_postings,
  title: 'Title',
  dynamicHyperlinks: data =>
    pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, {
      specId: data?.specialisation?.id,
      id: data?.id,
    }),
  insert: ({ data, url }) => (
    <Table.EntityCell
      useIcon={data.specialisation?.icon || FUNCTION_DEFAULT_ICON}
      url={url}
    >
      {data.name}
    </Table.EntityCell>
  ),
}

export const jobDescriptionGenericNameColumn: ColumnInterface<JobPostingListInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  dynamicHyperlinks: data =>
    pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, {
      specId: data?.specialisation?.id,
      id: data?.id,
    }),
  selectorsKey: selectorKeys.job_postings,
  title: 'Title',
}

export const jobDescriptionCandidatesColumn: ColumnInterface<JobPostingListInterface> = {
  type: CellTypes.insert,
  idPoint: 'candidates_count',
  dataPoint: 'candidates_count',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Candidates',
  insert: ({ data }) => (
    <Flex justifyContent="right">
      <HStack gap="s-4" align="center">
        <Text>{data.candidates_count}</Text>
        <IconButton
          use={InternalLink}
          color={Token.color.blue}
          size={16}
          useIcon="LinkExternal"
          to={pathToUrl(ROUTES.FORMS.JOB_POSTING.CANDIDATES, {
            id: data.id,
            specId: data?.specialisation?.id,
          })}
          onClick={e => {
            e.stopPropagation()
          }}
        />
      </HStack>
    </Flex>
  ),
}

export const jobDescriptionHiringManagersColumn: ColumnInterface<JobPostingListInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'hiring_managers',
    dataPoint: 'hiring_managers',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Hiring managers',
    insert: ({ data }) => (
      <MultipleEmployees maxEmployees={2} employees={data.hiring_managers} />
    ),
  }

export const jobDescriptionSpecialisationColumn: ColumnInterface<JobPostingListInterface> =
  {
    type: CellTypes.text,
    idPoint: 'specialisation.id',
    dataPoint: 'specialisation.name',
    sortKey: 'specialisation__name',
    filterKey: 'specialisation__id',
    selectorsKey: selectorKeys.specialisations,
    title: 'Specialisation',
  }

export const jobDescriptionRecruiterColumn: ColumnInterface<JobPostingListInterface> = {
  type: CellTypes.insert,
  idPoint: 'recruiter',
  dataPoint: 'recruiter',
  sortKey: 'recruiter__display_name',
  filterKey: 'recruiter__id',
  selectorsKey: selectorKeys.employee,
  title: 'Recruiter',
  insert: ({ data }) => <UserWithAvatar {...data.recruiter} />,
}

export const jobDescriptionLocationsColumn: ColumnInterface<JobPostingListInterface> = {
  type: CellTypes.insert,
  idPoint: 'locations',
  dataPoint: 'locations',
  sortKey: null,
  filterKey: 'locations__id',
  selectorsKey: selectorKeys.location,
  title: 'Locations',
  insert: ({ data }) => {
    let source = ''
    switch (data.location_source) {
      case LocationSource.manual:
        source = '(Selected manually)'
        break

      case LocationSource.requisition:
        source = '(From requisitions)'
        break
    }
    return (
      <StyledTooltip
        placement="top"
        text={data.locations?.map(item => item.name)?.join(', ')}
        hide={!data.locations?.length}
      >
        {data.locations?.length || 0} {source}
      </StyledTooltip>
    )
  },
}

export const jobDescriptionOfficeLocationsColumn: ColumnInterface<JobPostingListInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'office_locations',
    dataPoint: 'office_locations',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Office locations',
    insert: ({ data }) =>
      data.locations
        ?.filter(item => item.type === 'office')
        ?.map(item => item.name)
        ?.join(', ') || '-',
  }

export const jobDescriptionRemoteLocationsColumn: ColumnInterface<JobPostingListInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'remote_locations',
    dataPoint: 'remote_locations',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Remote locations',
    insert: ({ data }) =>
      data.locations
        ?.filter(item => item.type === 'remote')
        ?.map(item => item.name)
        ?.join(', ') || '-',
  }

export const jobDescriptionPlatformsColumn: ColumnInterface<JobPostingListInterface> = {
  type: CellTypes.insert,
  idPoint: 'platforms',
  dataPoint: 'platforms',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Platforms',
}

export const jobDescriptionUsedInRequisitionsCountNameColumn: ColumnInterface<JobPostingListInterface> =
  {
    type: CellTypes.text,
    idPoint: 'requisitions_count',
    dataPoint: 'requisitions_count',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    colors: () => Token.color.greyTone50,
    textAlign: 'right',
    title: 'Headcount',
  }

const getPublishingStatusVariant = (status: PublishingStatuses) => {
  switch (status) {
    case PublishingStatuses.published_internally:
    case PublishingStatuses.fully_published:
      return 'success'

    default:
      return 'neutral'
  }
}

export const jobDescriptionPublishedStatusColumn: ColumnInterface<JobPostingListInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'status_display',
    dataPoint: 'status_display',
    sortKey: 'status',
    filterKey: 'status',
    selectorsKey: selectorKeys.postings_status,
    title: 'Publishing status',
    insert: ({ data }) => (
      <Table.StatusCell
        status={data.status_display}
        variant={getPublishingStatusVariant(data.status)}
      />
    ),
  }

export const jobDescriptionStatusColumn: ColumnInterface<JobPostingListInterface> = {
  type: CellTypes.insert,
  idPoint: 'approval_status',
  dataPoint: 'approval_status',
  sortKey: 'approval_status',
  filterKey: 'approval_status',
  selectorsKey: selectorKeys.posting_approval_status,
  title: 'Approval status',
  insert: ({ data }) => (
    <Table.StatusCell
      status={data.approval_status}
      variant={getDefaultApprovalStatusVariant(data.approval_status)}
    />
  ),
}

export const jobDescriptionActionColumn: ColumnInterface<JobPostingListInterface> = {
  type: CellTypes.insert,
  idPoint: 'action',
  dataPoint: 'action',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Actions',
}

export const recruiterLocationName: ColumnInterface<RecruiterLocationInterface> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Recruiter',
  insert: ({ data }) => <UserWithAvatar {...data.recruiter} />,
}

export const recruiterLocationLocations: ColumnInterface<RecruiterLocationInterface> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Locations',
  insert: ({ data }) => data.locations.map(item => item.location_name).join(', '),
}
