import React, { PropsWithChildren } from 'react'
import { Flex, Avatar, Ellipsis, IconName, AvatarProps } from '@revolut/ui-kit'
import TableCellLink from '@components/TableCellLink/TableCellLink'
import upperFirst from 'lodash/upperFirst'
import { useIsNewTable } from '@components/TableV2/hooks'
import { LocationDescriptor } from 'history'

interface EntityCellProps {
  useIcon?: IconName | null
  url?: LocationDescriptor
  iconProps?: Omit<AvatarProps, 'useIcon'>
}

export const EntityCell = ({
  useIcon,
  iconProps,
  url,
  children,
}: PropsWithChildren<EntityCellProps>) => {
  const isNewTable = useIsNewTable()
  const content = typeof children === 'string' ? upperFirst(children) : children

  return (
    <Flex gap="s-16" alignItems="center">
      {useIcon && isNewTable ? <Avatar useIcon={useIcon} {...(iconProps || {})} /> : null}
      <Ellipsis>
        {url ? <TableCellLink to={url}>{content}</TableCellLink> : content}
      </Ellipsis>
    </Flex>
  )
}
