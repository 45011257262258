import React from 'react'
import 'quill-paste-smart'
import 'react-quill/dist/quill.snow.css'
import { StringMap } from 'quill'
import ReactQuill, { ReactQuillProps } from 'react-quill'
import styled, { createGlobalStyle } from 'styled-components'

import {
  Color,
  Flex,
  Icon,
  Text,
  Token,
  Tooltip,
  Widget,
  useTooltip,
} from '@revolut/ui-kit'
import BottomInputMessage from '@components/BottomInputMessage/BottomInputMessage'

const GlobalStyles = createGlobalStyle`
  .ql-toolbar.ql-snow .ql-formats {
    margin-right: 2px;
  }

  .ql-toolbar.ql-snow {
    border: 0;
    border-bottom: 1px solid ${Token.color.greyTone10};
  }

  .ql-container.ql-snow {
    border: 0;
  }

  .ql-container {
    font: inherit;
    font-size: 1rem;
  }

  .ql-container > .ql-editor.ql-blank::before {
    font-style: normal;
  }

  ${new Array(9).fill(0).map(
    (_, i) => `
    .ql-editor .ql-indent-${i + 1}:not(.ql-direction-rtl) {
      padding-left: ${24 * (i + 1)}px;
    }
    .ql-editor li.ql-indent-${i + 1}:not(.ql-direction-rtl) {
      padding-left: ${24 + 24 * (i + 1)}px;
    }
    .ql-editor .ql-indent-${i + 1}.ql-direction-rtl.ql-align-right {
      padding-right: ${24 * (i + 1)}px;
    }
    .ql-editor li.ql-indent-${i + 1}.ql-direction-rtl.ql-align-right {
      padding-right: ${24 + 24 * (i + 1)}px;
    }
    `,
  )}
  
  .ql-editor ul {
      padding-left: 24px;
  }
`

// dynamic styles should be wrapped to create a separate styled component class for each instance
const Wrap = styled.div<{
  addMarginToParagraphs?: boolean
  minHeight?: string
  nonResizable?: boolean
  noPadding?: boolean
  fontSize?: string
  color?: Color
  breakLinks?: boolean
}>`
  .ql-editor {
    overflow-y: scroll;
    resize: ${props => (props.nonResizable ? '' : 'vertical')};
    min-height: ${props => (props.minHeight === undefined ? '' : props.minHeight)};
    height: ${props => (props.minHeight === undefined ? '' : props.minHeight)};
    padding: ${props => (props.noPadding ? '0' : '')};
    font-size: ${props => (props.fontSize ? props.fontSize : 'inherit')};
    color: ${props => (props.color ? props.color : 'inherit')};
  }

  .ql-editor p {
    margin-bottom: ${props => (props.addMarginToParagraphs ? '16px' : '0')};
  }
  .ql-editor a {
    word-break: ${props => (props.breakLinks ? 'break-all' : '')};
  }

  .ql-editor.ql-blank::before {
    color: ${Token.color.greyTone10};
  }

  .ql-toolbar .ql-stroke {
    fill: none;
    stroke: ${Token.color.foreground};
  }

  .ql-toolbar .ql-fill {
    fill: ${Token.color.foreground};
    stroke: none;
  }

  .ql-toolbar .ql-picker-label {
    color: ${Token.color.foreground};
  }
`

const Content = styled(Widget)<{
  greyBg?: boolean
  transparentBg?: boolean
  hasError?: boolean
}>`
  background-color: ${props => {
    const defaultColor = props.greyBg
      ? Token.color.greyTone8
      : props.transparentBg
      ? 'transparent'
      : Token.color.widgetBackground
    return props.hasError ? Token.color.red_10 : defaultColor
  }};
`

export const DEFAULT_MODULES = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
    ['clean'],
  ],
}

export interface HTMLEditorProps extends ReactQuillProps {
  value?: string
  onChange: (value?: string) => void
  height?: number | string
  placeholder?: string
  readOnly?: boolean
  modules?: StringMap
  name?: string
  hasError?: boolean
  maxCharacters?: number
  message?: React.ReactNode | string | null
  addMarginToParagraphs?: boolean
  nonResizable?: boolean
  noPadding?: boolean
  fontSize?: string
  color?: Color
  greyBg?: boolean
  transparentBg?: boolean
  'data-name'?: string
  breakLinks?: boolean
}

const HTMLEditor = ({
  value,
  onChange,
  height = 600,
  placeholder,
  readOnly,
  modules,
  name,
  hasError,
  maxCharacters,
  message,
  addMarginToParagraphs = false,
  nonResizable,
  noPadding,
  fontSize,
  color,
  greyBg,
  transparentBg,
  breakLinks,
  ...rest
}: HTMLEditorProps) => {
  const tooltip = useTooltip()
  return (
    <Wrap
      minHeight={typeof height === 'number' ? `${height}px` : height}
      addMarginToParagraphs={addMarginToParagraphs}
      nonResizable={nonResizable}
      noPadding={noPadding}
      fontSize={fontSize}
      color={color}
      breakLinks={breakLinks}
    >
      <Content
        data-name={rest['data-name']}
        aria-label={rest['data-name']}
        hasError={hasError}
        greyBg={greyBg}
        transparentBg={transparentBg}
      >
        <GlobalStyles />
        <ReactQuill
          theme="snow"
          value={
            !rest?.defaultValue ? value?.replaceAll('\n', '<br />') || '' : undefined
          }
          onChange={newValue => {
            if (maxCharacters !== undefined && newValue.length > maxCharacters) {
              return
            }
            onChange(newValue)
          }}
          readOnly={readOnly}
          placeholder={placeholder}
          // https://github.com/zenoamaro/react-quill/issues/409
          // matchVisual: false needed to avoid inserting extra br before a list
          modules={{
            ...modules,
            clipboard: { matchVisual: false },
            toolbar: readOnly ? false : modules?.toolbar,
          }}
          {...rest}
        />
      </Content>
      <Flex justifyContent="space-between" alignItems="center">
        <span>
          <BottomInputMessage hasError={hasError} message={message} />
        </span>
        {maxCharacters && (
          <Flex alignItems="center" py="s-2" gap="s-4">
            <Text variant="small" color={Token.color.greyTone50}>
              {value?.length ?? 0}/{maxCharacters}
            </Text>
            <Icon
              name="ExclamationMarkOutline"
              size={12}
              {...tooltip.getAnchorProps()}
              color={Token.color.greyTone50}
            />
            <Tooltip {...tooltip.getTargetProps()}>includes html formatting</Tooltip>
          </Flex>
        )}
      </Flex>
    </Wrap>
  )
}

export default HTMLEditor
