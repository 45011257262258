import React from 'react'
import {
  ActionButton,
  ActionButtonSkeleton,
  Flex,
  HStack,
  Item,
  ItemSkeleton,
  Text,
  TextSkeleton,
} from '@revolut/ui-kit'

interface Props {
  id: string
  name: string
  value: string
  description: string
  isIncrementDisabled: boolean
  isDecrementDisabled: boolean
  handleIncrementClick: VoidFunction
  handleDecrementClick: VoidFunction
}

export const GradeItem = ({
  id,
  name,
  value,
  description,
  isIncrementDisabled,
  isDecrementDisabled,
  handleIncrementClick,
  handleDecrementClick,
}: Props) => (
  <Item px={0}>
    <Item.Content>
      <Item.Title>{name}</Item.Title>
      <Item.Description>{description}</Item.Description>
    </Item.Content>
    <Item.Side>
      <HStack space="s-16" align="center">
        <ActionButton
          useIcon="Minus"
          iconOnly
          disabled={isDecrementDisabled}
          style={{ width: 50 }}
          onClick={handleDecrementClick}
          data-testid={`decrement-${id}`}
        >
          Minus
        </ActionButton>
        <Flex justifyContent="center" width={40}>
          <Text fontWeight={500}>{value}</Text>
        </Flex>
        <ActionButton
          useIcon="Plus"
          iconOnly
          disabled={isIncrementDisabled}
          style={{ width: 50 }}
          onClick={handleIncrementClick}
          data-testid={`increment-${id}`}
        >
          Plus
        </ActionButton>
      </HStack>
    </Item.Side>
  </Item>
)

export const GradeItemSkeleton = () => (
  <ItemSkeleton paddingX={0}>
    <ItemSkeleton.Content>
      <ItemSkeleton.Title />
      <ItemSkeleton.Description />
    </ItemSkeleton.Content>
    <ItemSkeleton.Side>
      <HStack space="s-16" align="center">
        <ActionButtonSkeleton />
        <TextSkeleton width={50} />
        <ActionButtonSkeleton />
      </HStack>
    </ItemSkeleton.Side>
  </ItemSkeleton>
)
