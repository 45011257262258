import React from 'react'
import { ActionButton, Details, Group, HStack, Text, Token } from '@revolut/ui-kit'
import pluralize from 'pluralize'
import Table from '@components/TableV2/Table'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { EmployeeInterface } from '@src/interfaces/employees'
import { TableNames } from '@src/constants/table'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'
import { RowInterface } from '@src/interfaces/data'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  eligibleEmployeeContractColumn,
  eligibleEmployeeLocationColumn,
  eligibleEmployeeStartedAtColumn,
  eligibleEmployeeTypeColumn,
  employeeNameColumn,
  employeeStatusColumn,
} from '@src/constants/columns/employee'
import { EligibleGroupInterface } from '@src/interfaces/reviewCycles'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'

const maxRowsCount = 5

const ROW: RowInterface<EmployeeInterface> = {
  linkToForm: data =>
    navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: data.id })),
  cells: [
    {
      ...employeeNameColumn,
      width: 200,
    },
    {
      ...eligibleEmployeeTypeColumn,
      width: 90,
    },
    {
      ...eligibleEmployeeContractColumn,
      width: 100,
    },
    {
      ...eligibleEmployeeLocationColumn,
      width: 90,
    },
    {
      ...eligibleEmployeeStartedAtColumn,
      width: 110,
    },
    {
      ...employeeStatusColumn,
      width: 90,
    },
  ],
}

interface Props {
  title: string
  employeesCount: number
  filtersCount: number
  isEligible: boolean
  isScoreCardsEnabled: boolean
  employees: EmployeeInterface[]
  handleEdit: () => void
  handleDelete: () => Promise<void>
}

export const EligibleGroup = ({
  title,
  employeesCount,
  filtersCount,
  isEligible,
  isScoreCardsEnabled,
  employees,
  handleEdit,
  handleDelete,
}: Props) => {
  const visibleCount = Math.min(employeesCount, maxRowsCount)
  const visibleEmployees = employees.slice(0, visibleCount)

  const status = `${pluralize('employee', employeesCount, true)} selected`
  const filterDetails = `${pluralize('filter', filtersCount, true)} selected`
  const groupDetails = isEligible
    ? `Scorecards: ${isScoreCardsEnabled ? 'enabled' : 'disabled'}`
    : 'Set as ineligible'

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Group>
          <Details variant="header" style={{ padding: 0 }}>
            <Details.Title variant="heading3">{title}</Details.Title>
            <Details.Content>
              <HStack space="s-8">
                <ActionButton onClick={handleEdit}>Edit</ActionButton>
                <NewSaveButtonWithPopup<EligibleGroupInterface>
                  confirmationDialogue={{
                    body: (
                      <>
                        <Text>Delete this group?</Text>{' '}
                        <Text fontWeight={700}>This action is not reversible.</Text>
                      </>
                    ),
                  }}
                  variant="negative"
                  small
                  onClick={handleDelete}
                >
                  Delete
                </NewSaveButtonWithPopup>
              </HStack>
            </Details.Content>
          </Details>
          <Details>
            <Details.Title>{filterDetails}</Details.Title>
            <Details.Content color={Token.color.greyTone50}>
              {groupDetails}
            </Details.Content>
          </Details>
        </Group>
      </Table.Widget.Info>
      <Table.Widget.Status>
        <Text>{status}</Text>
      </Table.Widget.Status>
      <Table.Widget.Table>
        <AdjustableTable<EmployeeInterface>
          name={TableNames.EligibleGroups}
          row={ROW}
          hideCount
          data={visibleEmployees}
          count={visibleCount}
          disabledFiltering
          emptyState={
            <EmptyTableRaw title="There is no eligible employees in the group" />
          }
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
