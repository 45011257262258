import { FieldOptions, IdAndName, Statuses } from '@src/interfaces/index'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { EmployeeOptionInterface, IdStatuses } from '@src/interfaces/employees'
import { ApplicationFormSectionInterface } from '@src/interfaces/applicationForm'
import { SidebarJobDescription } from '@src/interfaces/jobDescription'
import { LocationInterface, LocationType } from '@src/interfaces/locations'
import { SpecialisationHiringProcess } from './hiringProccess'
import { OptionInterface } from './selectors'
import { IconName } from '@revolut/ui-kit'

export interface JobPostingLocationInterface
  extends Pick<
    LocationInterface,
    | 'location_name'
    | 'id'
    | 'posting_compensation_enabled'
    | 'posting_compensation_period'
  > {
  name: string
  type?: LocationType | null
}

export interface RecruiterLocationInterface {
  id: number
  recruiter: EmployeeOptionInterface
  locations: JobPostingLocationInterface[]
}

export enum PublishingStatuses {
  unpublished = 'unpublished',
  published_internally = 'published_internally',
  fully_published = 'fully_published',
  closed = 'closed',
}

export interface JobPostingSalaryBandInterface {
  iso_code: string
  name: string
  posting_compensation_period?: 'monthly' | 'yearly'
  symbol?: string
  lower_band: number | null
  upper_band: number | null
}

export interface JobPostingSalaryBandsByLocationInterface {
  [key: string]: JobPostingSalaryBandInterface
}

export interface JobPostingInterface {
  id: number
  name: string
  is_published?: boolean
  is_generated?: boolean
  locations?: JobPostingLocationInterface[]
  specialisation?: {
    id: string | number
    name?: string
    role_id?: number
    status?: Statuses
    icon?: IconName
  }
  requisitions_count?: number
  sections?: { title: string; content: string }[]
  status: PublishingStatuses
  status_display: string
  title: string
  field_options?: FieldOptions
  approval_status: ApprovalStatuses
  location_source: LocationSource
  recruiter: {
    display_name?: string
    full_name: string
    id: number
    name?: string
    status: IdStatuses
    email?: string
  }
  coordinator?: {
    display_name?: string
    full_name: string
    id: number
    name?: string
    status: IdStatuses
    email?: string
  }
  application_form_sections: ApplicationFormSectionInterface[]
  recruiter_locations: RecruiterLocationInterface[]
  rejection_reason?: string
  salary_bands_by_location?: JobPostingSalaryBandsByLocationInterface
  public_id?: string
  apply_url?: string
  careers_position_url?: string
  creation_date_time?: string
  update_date_time?: string
  hiring_process?: SpecialisationHiringProcess
  employment_type?: OptionInterface
  presentation_video_url?: string
}

export interface JobPostingListInterface
  extends Pick<
    JobPostingInterface,
    | 'id'
    | 'public_id'
    | 'name'
    | 'locations'
    | 'coordinator'
    | 'recruiter'
    | 'hiring_process'
    | 'specialisation'
    | 'creation_date_time'
    | 'employment_type'
    | 'approval_status'
    | 'is_generated'
    | 'status_display'
    | 'status'
    | 'requisitions_count'
    | 'location_source'
  > {
  hiring_managers: EmployeeOptionInterface[]
  candidates_count: number
}

export interface FullJobPostingInterface extends Omit<JobPostingInterface, 'sections'> {
  sections?: SidebarJobDescription[]
}

export enum LocationSource {
  requisition = 'requisition',
  manual = 'manual',
}

export interface JobPostingLocationTypeInterface {
  id: number
  name: string
  locations: JobPostingLocationInterface[]
  specialisation?: IdAndName
}

export interface LinkedInPosting {
  id: number
  public_id: string
  url: string
  status: Statuses.pending | Statuses.rejected | Statuses.closed | 'published'
  status_detail: null | string
  is_promoted: boolean
  job_posting: {
    id: number
    name: string
  }
  linkedin_location: string
  location: {
    id: number
    location_name: string
    name: string
  }
  is_pending_changes: boolean
  expiry_date_time: string
}

export type JobPostingStatCategory =
  | 'Total'
  | 'own_pipelines'
  | 'report_pipelines'
  | 'shared_with_me_pipelines'
  | 'access_pipelines'

export interface JobPostingStatsInterface {
  category: JobPostingStatCategory
  label: string
  count: number
  filters: (string | number)[]
  filter_field_name: string
}

export interface JobsStatsInterface {
  requisitions_count: number
  job_postings_count: number
}
