import AvatarUploadPopup from '@components/AvatarUploadPopup/AvatarUploadPopup'
import React from 'react'
import { StatusPopup, useStatusPopup, IconName } from '@revolut/ui-kit'
import { pushNotification } from '@src/store/notifications/actions'
import { NotificationTypes } from '@src/store/notifications/types'
import { ERROR_DEFAULT_DURATION } from '@src/constants/notifications'
import { MutationResult } from '@src/features/SettingsButtons/types'

interface UserAvatarUploadProps<T> {
  open: boolean
  onClose: () => void
  onSuccess?: (data?: T) => void
  api: () => MutationResult<T>
  id: number
  icon?: IconName | null
}

export const EntityAvatarUpload = <T extends {}>({
  open,
  onClose,
  onSuccess,
  api,
  id,
  icon,
}: UserAvatarUploadProps<T>) => {
  const statusPopup = useStatusPopup()
  const { mutateAsync: updateEntity } = api()

  const onSaveIcon = async (iconName: IconName) => {
    try {
      const resp = await updateEntity([id, { icon: iconName }])
      onSuccess?.(resp.data)
      onClose()
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>Icon was set</StatusPopup.Title>
        </StatusPopup>,
      )
    } catch {
      pushNotification({
        type: NotificationTypes.error,
        value: 'Failed to set icon',
        duration: ERROR_DEFAULT_DURATION,
      })
    }
  }

  // @ts-ignore
  const onSavePhoto = async (file: File) => {
    try {
      // TODO: implement upload - REVCOR-2898

      onSuccess?.(undefined)
      onClose()
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>Icon was set</StatusPopup.Title>
        </StatusPopup>,
      )
    } catch {
      pushNotification({
        type: NotificationTypes.error,
        value: 'Failed to upload avatar file',
        duration: ERROR_DEFAULT_DURATION,
      })
    }
  }

  const onRemovePhoto = async () => {
    try {
      // TODO: implement removing photo - REVCOR-2898

      const resp = await updateEntity([id, { icon: null }])

      onSuccess?.(resp.data)
      onClose()
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>Icon removed</StatusPopup.Title>
        </StatusPopup>,
      )
    } catch {
      pushNotification({
        type: NotificationTypes.error,
        value: 'Failed to remove icon',
        duration: ERROR_DEFAULT_DURATION,
      })
    }
  }

  return (
    <AvatarUploadPopup
      open={open}
      onClose={onClose}
      onSave={onSavePhoto}
      onSaveIcon={onSaveIcon}
      onRemove={onRemovePhoto}
      title="Set icon"
      minHeight={192}
      minWidth={192}
      withIcons
      withFile={false}
      icon={icon}
    />
  )
}
