import React from 'react'
import { useTable } from '@components/Table/hooks'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { DepartmentInterface } from '@src/interfaces/deparment'
import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { getDepartmentKarma, getDepartmentKarmaStats } from '@src/api/department'
import { KarmaInterface, KarmaStatsInterface } from '@src/interfaces/karma'
import { formatNumber, formatPercentage } from '@src/utils/format'
import {
  karmaCountColumn,
  karmaCreatedAtColumn,
  karmaIssueIdColumn,
  karmaOwnerColumn,
  karmaSourceColumn,
  karmaSummaryColumn,
  karmaTeamColumn,
} from '@src/constants/columns/karma'
import { selectorKeys } from '@src/constants/api'
import { ActionButton, Text, Token } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import Tooltip from '@components/Tooltip/Tooltip'
import { TableNames } from '@src/constants/table'
import Table from '@src/components/TableV2/Table'

const ROW: RowInterface<KarmaInterface> = {
  cells: [
    {
      ...karmaCountColumn,
      width: 120,
    },
    {
      ...karmaTeamColumn,
      width: 300,
    },
    {
      ...karmaOwnerColumn,
      width: 200,
    },
    {
      ...karmaSummaryColumn,
      width: 500,
    },
    {
      ...karmaCreatedAtColumn,
      width: 160,
    },
    {
      ...karmaIssueIdColumn,
      width: 120,
    },
    {
      ...karmaSourceColumn,
      width: 120,
    },
  ],
}

interface Props {
  data: DepartmentInterface
}

const Karma = ({ data }: Props) => {
  const initialFilters = [
    {
      filters: [{ name: `0`, id: 0 }],
      columnName: 'review_cycle__offset',
      nonResettable: true,
    },
  ]

  const initialSortBy = [
    {
      sortBy: 'issue_date_time',
      direction: SORT_DIRECTION.ASC,
    },
  ]

  const table = useTable<KarmaInterface, KarmaStatsInterface>(
    {
      getItems: getDepartmentKarma(data.id),
      getStats: getDepartmentKarmaStats(data.id),
    },
    initialFilters,
    initialSortBy,
  )

  return (
    <>
      <Table.Widget.Info>
        <CycleFilter
          type={CycleFilterType.NewUI}
          onFilterChange={table.onFilterChange}
          columnName="review_cycle__offset"
          filter={table.filterBy}
          selector={selectorKeys.half_yearly_cycles}
        />
        <Stat
          label="Total karma"
          val={
            table?.stats?.total_karma !== undefined ? (
              <Tooltip
                text="
                  Net points accumulated within the department to be shared equally per
                  employee.
                  \n Points are won / lost based on scorecards used within
                  Second Line of Defence (2LOD) / Third Line of Defence (3LOD) processes.
              "
                placement="bottom"
              >
                <Text color={Token.color.foreground}>
                  {formatNumber(table?.stats?.total_karma)}
                </Text>
              </Tooltip>
            ) : undefined
          }
        />
        <Stat
          label="Good karma"
          val={
            table?.stats?.good_karma !== undefined ? (
              <Text color={Token.color.green}>
                {formatNumber(table?.stats?.good_karma)}
              </Text>
            ) : undefined
          }
        />
        <Stat
          label="Bad karma"
          val={
            table?.stats?.good_karma !== undefined ? (
              <Text color={Token.color.red}>{formatNumber(table?.stats?.bad_karma)}</Text>
            ) : undefined
          }
        />
        <Stat
          label="Max karma"
          val={
            table?.stats?.total_max_karma !== undefined ? (
              <Text>{formatNumber(table?.stats?.total_max_karma)}</Text>
            ) : undefined
          }
        />
        <Stat
          label="Karma percentage"
          val={
            table?.stats?.karma_percentage !== undefined ? (
              <Text
                color={
                  table?.stats?.karma_percentage >= 0.5
                    ? Token.color.green
                    : Token.color.red
                }
              >
                {formatPercentage(table?.stats?.karma_percentage)}
              </Text>
            ) : undefined
          }
        />
      </Table.Widget.Info>
      <Table.Widget.Actions>
        <ActionButton
          target="_blank"
          rel="noreferrer noopener"
          href="https://revolut.atlassian.net/wiki/spaces/REV/pages/1746698707/Karma"
          use="a"
          mr="s-16"
          useIcon="InfoOutline"
        >
          More about Karma
        </ActionButton>
      </Table.Widget.Actions>

      <Table.Widget.Table>
        <AdjustableTable<KarmaInterface, KarmaStatsInterface>
          name={TableNames.DepartmentKarma}
          useWindowScroll
          dataType="Karma Score"
          row={ROW}
          {...table}
          noDataMessage="All karma scores will appear here"
        />
      </Table.Widget.Table>
    </>
  )
}

export const KarmaWithWrapper = (props: Props) => {
  return (
    <Table.Widget>
      <Karma {...props} />
    </Table.Widget>
  )
}

export default Karma
