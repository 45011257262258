import React from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { useNavigation } from '@src/pages/Forms/ReviewCycle/ReviewCycle/hooks/useNavigation'

interface Props {
  cycle: ReviewCyclesInterface
}

export const CalibrationActions = ({ cycle }: Props) => {
  const { toPerformanceSummary } = useNavigation()

  return (
    <MoreBar>
      <MoreBar.Action useIcon="16/ListBullet" onClick={toPerformanceSummary(cycle)}>
        Performance summary
      </MoreBar.Action>
    </MoreBar>
  )
}
