import React from 'react'
import { Avatar, Item, Switch, SwitchProps, Text, Token } from '@revolut/ui-kit'
import { ImageProp } from '@revolut/ui-kit/types/dist/types/image'

interface Props extends Omit<SwitchProps, 'title' | 'onClick'> {
  avatar?: React.ReactNode
  image?: ImageProp
  onClick?: (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  name?: string
  title: React.ReactNode
  description?: React.ReactNode
  error?: string
  checked?: boolean
  disabled?: boolean
}

export const ItemSwitch = ({
  avatar,
  image,
  onClick,
  name,
  title,
  description,
  error,
  onChange,
  checked,
  disabled,
  ...props
}: Props) => {
  return (
    <Item
      use="label"
      data-name={name}
      onClick={e => {
        onClick?.(e)
      }}
    >
      {avatar || image ? (
        <Item.Avatar>
          {avatar || <Avatar variant="brand" size={40} image={image} />}
        </Item.Avatar>
      ) : null}

      <Item.Content>
        <Item.Title>{title}</Item.Title>
        {(description || error) && (
          <Item.Description>
            {description}
            {error ? (
              <>
                <br />
                <Text color={Token.color.danger}>{error}</Text>
              </>
            ) : null}
          </Item.Description>
        )}
      </Item.Content>
      <Item.Side>
        <Switch onChange={onChange} checked={checked} disabled={disabled} {...props} />
      </Item.Side>
    </Item>
  )
}
