import React, { ReactNode } from 'react'
import { Ellipsis, Flex, Text } from '@revolut/ui-kit'
import { capitalize } from 'lodash'
import { getCycleOffsetTitleAndColor } from '@src/utils/performance'
import { OptionInterface } from '@src/interfaces/selectors'

export const OptionItem = ({
  label,
  value,
}: {
  label?: ReactNode
  value: OptionInterface
}) => {
  const { title, color } = getCycleOffsetTitleAndColor(
    value.offset ? Number(value.offset) : Number(value.id),
  )

  return (
    <Flex flexWrap="nowrap" gap="s-8" alignItems="center">
      <Ellipsis>
        <Text variant="emphasis2">{label}</Text>
      </Ellipsis>
      <Text variant="emphasis2" color={color}>
        {capitalize(title)}
      </Text>
    </Flex>
  )
}
