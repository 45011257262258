import React from 'react'
import { ActionButton, Avatar, Box, Details, Group, Item, Widget } from '@revolut/ui-kit'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getCalibrationMethodTitle } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CalibrationLogic/constants'
import { GradesDistribution } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CalibrationLogic/GradesDistribution'

interface Props {
  cycle: ReviewCyclesInterface
}

export const CalibrationLogic = ({ cycle }: Props) => {
  const {
    id,
    grade_calculation_method: calibrationMethod,
    grade_distribution: gradeDistribution,
  } = cycle

  const editCalibrationLogicPath = pathToUrl(ROUTES.FORMS.REVIEW_CYCLES.GRADE, { id })
  const calibrationMethodTitle = calibrationMethod
    ? getCalibrationMethodTitle(calibrationMethod)
    : 'No calculation method set'

  return (
    <Widget>
      <Item>
        <Item.Avatar>
          <Avatar useIcon="IndustrialGear" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Calculation & Calibration logic</Item.Title>
        </Item.Content>
        <Item.Side>
          <Item.Value>
            <ActionButton to={editCalibrationLogicPath} use={InternalLink}>
              Edit
            </ActionButton>
          </Item.Value>
        </Item.Side>
      </Item>
      <Box px="s-16">
        <Group>
          <Details>
            <Details.Title>Performance grade logic</Details.Title>
            <Details.Content>{calibrationMethodTitle}</Details.Content>
          </Details>
          {gradeDistribution && (
            <Details>
              <Details.Title>Calibration grade distribution</Details.Title>
              <Details.Content>
                <GradesDistribution cycle={cycle} />
              </Details.Content>
            </Details>
          )}
        </Group>
      </Box>
    </Widget>
  )
}
