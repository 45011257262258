import { ReviewCyclesInterface, ReviewCycleStatus } from '@src/interfaces/reviewCycles'

interface CycleModel {
  isManual: (cycle: ReviewCyclesInterface) => boolean
  isTest: (cycle: ReviewCyclesInterface) => boolean
  hasAutoScorecardsGeneration: (cycle: ReviewCyclesInterface) => boolean
  getStartDate: (cycle: ReviewCyclesInterface) => string
  getEndDate: (cycle: ReviewCyclesInterface) => string
  isClosed: (cycle: ReviewCyclesInterface) => boolean
}

const isManual = (cycle: ReviewCyclesInterface): boolean =>
  !!cycle.is_manual_stage_switch_enabled

const isTest = (cycle: ReviewCyclesInterface): boolean => cycle.is_test

const hasAutoScorecardsGeneration = (cycle: ReviewCyclesInterface): boolean =>
  cycle.enabled_scorecards_generations

const getStartDate = (cycle: ReviewCyclesInterface): string => cycle.start_date_time
const getEndDate = (cycle: ReviewCyclesInterface): string => cycle.end_date_time

const isClosed = (cycle: ReviewCyclesInterface): boolean =>
  cycle.status === ReviewCycleStatus.closed

const createCycleModel = (): CycleModel => {
  return {
    isManual,
    isTest,
    hasAutoScorecardsGeneration,
    getStartDate,
    getEndDate,
    isClosed,
  }
}

export const cycleModel = createCycleModel()
