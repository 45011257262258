import React from 'react'

import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { Statuses } from '@src/interfaces'
import { ActionButton, Box, Flex, Text, Token, chain } from '@revolut/ui-kit'
import AvatarSnippet from '@src/components/AvatarSnippet/AvatarSnippet'
import { ReviewCategory } from '@src/interfaces/performance'
import StyledLink from '@src/components/Atoms/StyledLink'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

interface Props {
  lineManager: EmployeeOptionInterface
  goalsStatus: Statuses.approved | Statuses.pending_approval | null | undefined
  canAddGoals: boolean
  onClickAddGoals: () => void
  isCreateGoalPending: boolean
  reviewCategory: ReviewCategory
}

const getStatusSettings = (status: Props['goalsStatus']) => {
  switch (status) {
    case Statuses.approved:
      return { color: Token.color.foreground, title: 'Approved' }
    case Statuses.pending_approval:
      return { color: Token.color.greyTone50, title: 'Pending' }
    default:
      return { color: Token.color.greyTone50, title: '' }
  }
}

export const GoalsStepReviewers = ({
  lineManager,
  goalsStatus,
  reviewCategory,
  canAddGoals,
  onClickAddGoals,
  isCreateGoalPending,
}: Props) => {
  const { color, title } = getStatusSettings(goalsStatus)
  return (
    <Box width="100%">
      <Flex
        pr="s-20"
        style={{ flexGrow: 1 }}
        justifyContent="space-between"
        alignItems="center"
      >
        <AvatarSnippet
          avatar={lineManager.avatar}
          status={
            goalsStatus === Statuses.pending_approval ? Statuses.approved : goalsStatus
          }
          fullName="Line Manager"
          text={chain(
            <StyledLink
              onClick={e => e.stopPropagation()}
              to={getLocationDescriptor(
                pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                  id: lineManager.id,
                }),
              )}
            >
              {lineManager.full_name}
            </StyledLink>,
            `Set ${
              reviewCategory === ReviewCategory.Probation ? 'probation' : 'PIP'
            } goals`,
          )}
          noItemWrap
        />

        {canAddGoals ? (
          <ActionButton
            useIcon="Plus"
            onClick={onClickAddGoals}
            pending={isCreateGoalPending}
          >
            Add goals
          </ActionButton>
        ) : (
          <Text variant="body1" color={color}>
            {title}
          </Text>
        )}
      </Flex>
    </Box>
  )
}
